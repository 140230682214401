<template>
  <div class="hello">
<div class="bg-white" id='products'>
    <header class="bg-[#FCF8F1] bg-opacity-30">
        <div class="px-4 mx-auto sm:px-6 lg:px-8">
            <div class="flex items-center justify-between h-16 lg:h-20">
                <div class="flex-shrink-0">
                    <a href="#" title="" class="flex">
                        <img class="w-auto h-8" src="@/assets/images/logo.png" alt="" />
                    </a>
                </div>

                <button type="button" class="inline-flex p-2 text-black transition-all duration-200 rounded-md lg:hidden focus:bg-gray-100 hover:bg-gray-100">
                    <!-- Menu open: "hidden", Menu closed: "block" -->
                    <svg class="block w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h16M4 16h16"></path>
                    </svg>

                    <!-- Menu open: "block", Menu closed: "hidden" -->
                    <svg class="hidden w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>

                <div class="hidden lg:flex lg:items-center lg:justify-center lg:space-x-10">
                    <a href="#features" class="text-base text-black transition-all duration-200 hover:text-opacity-80"> Products </a>

                    <a href="#features" class="text-base text-black transition-all duration-200 hover:text-opacity-80"> Features </a>

                    <a href="#reviews"  class="text-base text-black transition-all duration-200 hover:text-opacity-80"> Reviews </a>

                    <a href="#about" class="text-base text-black transition-all duration-200 hover:text-opacity-80"> About </a>
                </div>


            </div>
        </div>
    </header>

    <section class="bg-[#FCF8F1] bg-opacity-30 py-10 sm:py-16 lg:py-24">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
                <div>
                    <p class="text-base font-semibold tracking-wider text-blue-600 uppercase">高效背单词，轻松学习英语</p>
                    <h1 class="mt-4 text-4xl font-bold text-black lg:mt-8 sm:text-4xl xl:text-6xl">
                      <!--
                      Connect & learn from the experts
                      -->
                      多背单词&轻松背单词
                    </h1>
                    <p class="mt-4 text-base text-black lg:mt-8 sm:text-xl">智能记忆算法，背单词无上限.</p>
                    <p class="mt-4 text-base text-black lg:mt-8 sm:text-xl">多背单词为你提供最科学高效的背单词方法，助你轻松应对各种英语考试和日常交流！</p>
                    <a href="https://apps.apple.com/us/app/id6464281257?platform=iphone" target='blank' class="inline-flex items-center px-6 py-4 mt-8 font-semibold text-black transition-all duration-200 bg-yellow-300 rounded-full lg:mt-16 hover:bg-yellow-400 focus:bg-yellow-400" role="button">
                        App Store下载
                        <svg class="w-6 h-6 ml-8 -mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </a>
                    <br>
                    <a href="https://fanyiniao.oss-cn-hangzhou.aliyuncs.com/soft/app-release.apk" target='blank' class="inline-flex items-center px-6 mt-4 py-4 font-semibold text-black transition-all duration-200 bg-blue-300 rounded-full lg:mt-6 hover:bg-blue-400 focus:bg-blue-400" role="button">
                        Android下载
                        <svg class="w-6 h-6 ml-8 -mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </a>
                    <br>
                    <a href="https://www.fanyiniao.com/" target="blank" class="inline-flex items-center px-6 mt-4 py-4 font-semibold text-black transition-all duration-200 bg-red-300 rounded-full lg:mt-6 hover:bg-red-400 focus:bg-red-400" role="button">
                        PC版下载
                        <svg class="w-6 h-6 ml-8 -mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </a>
                  </div>

                <div>
                    <img class="w-full" src="@/assets/images/index-bg.png" alt="" />
                </div>
            </div>
        </div>
    </section>
</div>

<section class="py-10 bg-white sm:py-16 lg:py-24" id='features'>
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="grid grid-cols-1 gap-12 text-center sm:grid-cols-2 md:grid-cols-3 lg:gap-y-16">
            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-blue-100" width="72" height="75" viewBox="0 0 72 75" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M63.6911 28.8569C68.0911 48.8121 74.6037 61.2674 53.2349 65.9792C31.8661 70.6909 11.6224 61.2632 7.22232 41.308C2.82229 21.3528 3.6607 12.3967 25.0295 7.68503C46.3982 2.97331 59.2911 8.90171 63.6911 28.8569Z" />
                    </svg>
                    <svg class="absolute text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                        />
                    </svg>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">智能记忆算法</h3>
                <p class="mt-4 text-base text-gray-600">通过对学习者的学习行为和学习效果进行分析，智能记忆算法能够根据学习者的特点和需求，优化学习计划，确保每一个单词都能被牢牢记住。</p>
            </div>

            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-orange-100" width="62" height="64" viewBox="0 0 62 64" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M62 13.001C62 33.4355 53.9345 64.001 33.5 64.001C13.0655 64.001 0 50.435 0 30.0005C0 9.56596 2.56546 4.00021 23 4.00021C43.4345 4.00021 62 -7.43358 62 13.001Z" />
                    </svg>
                    <svg class="absolute text-orange-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 10V3L4 14h7v7l9-11h-7z" />
                    </svg>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">趣味学习模式</h3>
                <p class="mt-4 text-base text-gray-600">趣味学习模式是一款通过多种创新学习方法，旨在将枯燥的背单词过程变得轻松、有趣的学习工具。通过多个学习模式，让背单词过程变得轻松、有趣。</p>
            </div>

            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-green-100" width="66" height="68" viewBox="0 0 66 68" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M65.5 30C65.5 50.4345 46.4345 68 26 68C5.56546 68 0 50.4345 0 30C0 9.56546 12.5655 0 33 0C53.4345 0 65.5 9.56546 65.5 30Z" />
                    </svg>
                    <svg class="absolute text-green-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                    </svg>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">海量词库</h3>
                <p class="mt-4 text-base text-gray-600">全面覆盖多种考试和实用场景的英语词汇,覆盖中小学、四六级、雅思、托福等多种考试词汇，以及日常生活、工作中的实用词汇。
</p>
            </div>

            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-purple-100" width="66" height="68" viewBox="0 0 66 68" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M65.5 30C65.5 50.4345 46.4345 68 26 68C5.56546 68 0 50.4345 0 30C0 9.56546 12.5655 0 33 0C53.4345 0 65.5 9.56546 65.5 30Z" />
                    </svg>
                    <svg class="absolute text-purple-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                    </svg>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">快速复习单词</h3>
                <p class="mt-4 text-base text-gray-600">通过详细的学习进度跟踪和智能化的复习计划，确保词汇记忆的长期稳固。利用先进的算法和数据分析技术，优化记忆曲线，提高学习效率。</p>
            </div>

            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-gray-100" width="65" height="70" viewBox="0 0 65 70" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M64.5 26C64.5 46.4345 56.4345 70 36 70C15.5655 70 0 53.9345 0 33.5C0 13.0655 13.0655 0 33.5 0C53.9345 0 64.5 5.56546 64.5 26Z" />
                    </svg>
                    <svg class="absolute text-gray-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 10V3L4 14h7v7l9-11h-7z" />
                    </svg>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">AI英语老师</h3>
                <p class="mt-4 text-base text-gray-600">基于人工智能技术的教育工具。它利用自然语言处理和机器学习算法，能够进行智能对话、语法纠正、发音指导和写作评估。</p>
            </div>

            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-yellow-100" width="78" height="78" viewBox="0 0 78 78" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.49996 28.0002C4.09993 47.9554 14.1313 66.7885 35.5 71.5002C56.8688 76.2119 68.0999 58.4553 72.5 38.5001C76.9 18.5449 68.3688 12.711 47 7.99931C25.6312 3.28759 12.9 8.04499 8.49996 28.0002Z" />
                    </svg>
                    <svg class="absolute text-yellow-500 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                        />
                    </svg>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">音标大全</h3>
                <p class="mt-4 text-base text-gray-600">汇集了英语语言中的所有音标，包括元音和辅音。音标大全为学习者提供了一个便捷、全面的资源，以帮助他们提高发音准确性和流利度。</p>
            </div>

            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-gray-100" width="62" height="64" viewBox="0 0 62 64" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M62 13.001C62 33.4355 53.9345 64.001 33.5 64.001C13.0655 64.001 0 50.435 0 30.0005C0 9.56596 2.56546 4.00021 23 4.00021C43.4345 4.00021 62 -7.43358 62 13.001Z"></path>
                    </svg>
                    <svg class="absolute text-gray-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                    </svg>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">数据统计</h3>
                <p class="mt-4 text-base text-gray-600">数据统计工具用于记录和分析你每天学习单词的情况。这种工具不仅能帮助你了解自己的学习进度，优化你的学习方法，提高单词记忆效率。</p>
            </div>

            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-rose-100" width="72" height="75" viewBox="0 0 72 75" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M63.6911 28.8569C68.0911 48.8121 74.6037 61.2674 53.2349 65.9792C31.8661 70.6909 11.6224 61.2632 7.22232 41.308C2.82229 21.3528 3.6607 12.3967 25.0295 7.68503C46.3982 2.97331 59.2911 8.90171 63.6911 28.8569Z" />
                    </svg>
                    <svg class="absolute text-rose-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                        />
                    </svg>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">简洁的学习模式</h3>
                <p class="mt-4 text-base text-gray-600">通过简洁明了的界面和直观的功能，帮助学习者快速掌握大量词汇。这种模式侧重于减少干扰，让背单词变得更加轻松和愉快。</p>
            </div>

            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-lime-100" width="62" height="65" viewBox="0 0 62 65" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 13.0264C0 33.4609 8.06546 64.0264 28.5 64.0264C48.9345 64.0264 62 50.4604 62 30.0259C62 9.59135 59.4345 4.0256 39 4.0256C18.5655 4.0256 0 -7.40819 0 13.0264Z" />
                    </svg>

                    <svg class="absolute text-lime-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 10V3L4 14h7v7l9-11h-7z" />
                    </svg>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">内置英语词典</h3>
                <p class="mt-4 text-base text-gray-600">集成在学习应用中的强大工具，专为英语学习者设计，提供丰富的词汇、详细的释义、发音示范及多种学习功能，帮助用户全面提升英语水平。</p>
            </div>
        </div>
    </div>
</section>

 <section
      id="reviews"
      aria-label="What our customers are saying"
      class="bg-slate-50 py-20 sm:py-32"
    >
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto max-w-2xl md:text-center">
          <h2
            class="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            学习从来不是简单事情，我们会一直陪伴你
          </h2>
          <p class="mt-4 text-lg tracking-tight text-slate-700">
            "Success is not final, failure is not fatal: it is the courage to continue that counts." - Winston Churchill
          </p>
        </div>
        <ul
          role="list"
          class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3"
        >
          <li>
            <ul role="list" class="flex flex-col gap-y-6 sm:gap-y-8">
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                      这个英语查词app真的太好用了！它不仅可以查找单词的定义，而且还提供了例句和发音，这对于正在学英语的我来说非常有帮助。另外，还可以导出生词本，可以用到别的app上，太方便专项复习了！
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        星之胖胖丁
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        某高校大学生
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avatar-1.jpg"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                      在职业生涯中，英语是提示个人技能的必不可少的一部分。这个英语查词app非常专业，很多专业的高级生僻词都可以查询到，另外，它还提供了一些在线翻译和语音翻译的功能，方便我与国外客户进行交流。
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        Amy Hahn
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        某互联网企业高管
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avatar-4.jpg"
                        width="56"
                        height="56"
                        decoding="async"
                        data-nimg="1"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
            </ul>
          </li>
          <li>
            <ul role="list" class="flex flex-col gap-y-6 sm:gap-y-8">
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                       作为一名外贸行业从业者，我需要经常使用英语来与国外的客户沟通。翻译鸟非常实用，可以帮我快速查找单词的地道用法，让我在工作中更加得心应手。客户再也没疑惑过我的中式英语
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        Johney张
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        某外企白领
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avatar-2.jpg"
                        width="56"
                        height="56"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                      作为一名刚刚踏入职场的新人，我需要学习很多新的专业术语和词汇。翻译鸟提供了很多专业的词汇和场景用法，让我更加容易理解这些新的概念。同时，它还提供了一些关联词汇，让我更加有条理地学习英语。
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        团子有点肥
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        某高校应届生
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avatar-5.jpg"
                        width="56"
                        height="56"
                        decoding="async"
                        data-nimg="1"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
            </ul>
          </li>
          <li>
            <ul role="list" class="flex flex-col gap-y-6 sm:gap-y-8">
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                      在回归家庭3年的期间我一直在坚持积累单词，随时准备重新回到职场，我非常推荐生词本功能，可以反复查看、记忆、背诵。我的心得就是任何外语学习单词量都是最重要的。
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        echo
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        家庭主妇
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avatar-3.jpg"
                        width="56"
                        height="56"
                        decoding="async"
                        data-nimg="1"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                      我喜欢阅读英文文章和听英文歌曲。翻译鸟提供了英音和美音的功能，可以随时纠正我的发音，让我更加深入地了解英文歌曲。某种程度上，我现在的发音比考雅思时好多了。
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        一只妮
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        某英语爱好者
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avatar-6.jpg"
                        width="56"
                        height="56"
                        decoding="async"
                        data-nimg="1"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
    <section class="py-10 bg-gray-50 sm:pt-16 lg:pt-24" id='about'>
    <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div class="grid grid-cols-2 md:col-span-3 lg:grid-cols-6 gap-y-16 gap-x-12">
            <div class="col-span-2 md:col-span-3 lg:col-span-2 lg:pr-8">
                <img class="w-auto h-9" src="@/assets/images/logo.png" alt="" />

                <p class="text-base leading-relaxed text-gray-600 mt-7">多背单词为你提供最科学高效的背单词方法，助你轻松应对各种英语考试和日常交流！</p>
                <p class="text-base leading-relaxed text-gray-600 mt-7">杭州惊鹊网络科技有限公司</p>
                <a href='https://beian.miit.gov.cn/' target='_blank' class="text-base leading-relaxed text-gray-600 mt-1">浙ICP备14037565号-21</a>
            </div>

            <div>
                <p class="text-sm font-semibold tracking-widest text-gray-400 uppercase">Company</p>

                <ul class="mt-6 space-y-4">
                    <li>
                        <a href="#products" title="" class="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> Products </a>
                    </li>

                    <li>
                        <a href="#features" title="" class="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> Features </a>
                    </li>

                    <li>
                        <a href="#reviews" title="" class="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> Reviews </a>
                    </li>

                    <li>
                        <a href="#about" title="" class="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> About </a>
                    </li>
                </ul>
            </div>

            <div>
                <p class="text-sm font-semibold tracking-widest text-gray-400 uppercase">Features</p>

                <ul class="mt-6 space-y-4">
                    <li>
                        <a href="#" title="" class="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> 智能记忆算法 </a>
                    </li>

                    <li>
                        <a href="#" title="" class="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> 学单词无上限 </a>
                    </li>

                    <li>
                        <a href="#" title="" class="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> 智能AI老师 </a>
                    </li>

                    <li>
                        <a href="#" title="" class="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> 高效学习模式 </a>
                    </li>
                </ul>
            </div>

            <div class="col-span-2 md:col-span-1 lg:col-span-2 lg:pl-8">
                <p class="text-sm font-semibold tracking-widest text-gray-400 uppercase">Contact Email</p>

                <form action="#" method="POST" class="mt-6">
                    <div>
                        <label for="email" class="sr-only">Email</label>
                        <input type="email" name="email" value='phpdai@qq.com' id="email" placeholder="Enter your email" class="block w-full p-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600" />
                    </div>
                </form>
            </div>
        </div>

        <hr class="mt-16 mb-10 border-gray-200" />

        <p class="text-sm text-center text-gray-600">© Copyright 2021, All Rights Reserved by Postcraft</p>
    </div>
</section>

  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      selectIndex: 0,
    };
  },
  methods: {
    changePic(index){
      this.selectIndex = index
    },
    goAnchor(selector) {/*参数selector是id选择器（#anchor14）*/
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth"
      });
    },
  },
};
</script>

<style lang='scss' scoped>
body{
  font-family: "SF Pro SC","SF Pro Text","SF Pro Icons","PingFang SC","Helvetica Neue","Helvetica","Arial",sans-serif !important;
}
</style>
