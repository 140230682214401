import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router'
import IndexView from '@/views/Index.vue'
const routes = [
  {
    path: '/',
    name: 'default',
    component: IndexView,
  },
  {
    path: '/index',
    name: 'index',
    component: IndexView,
  },

]
const router = createRouter({
    //history: createWebHashHistory(),
    history: createWebHistory("/"),
    routes
  })

  export default router
