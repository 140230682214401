<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  mounted() {
    document.title = '多背单词 - 高效背单词，轻松学习英语';
  }
}
</script>
